import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'meta_ad',
    title: 'Meta ad',
    channel: 'social',
    platform: 'meta',
    description: 'Create an ad for a meta adset',
    addBrickText: 'Add ad',
    availableParentBricks: ['meta_adset'],
    availableChildBricks: [],
    outputAction: 'publish',
    additionalVars: ['meta_adset', 'meta_campaign', 'multiSocial_ad', 'multiSocial_adset', 'multiSocial_campaign'],
    defaultTab: 'adSetup',
    config: {
        general: {
            creativeSelector: {
                multiple: true,
                selectors: ['upload', 'template', 'campaignAssets'],
                fileType: ['image', 'video'],
                templateTypes: ['dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicImage', 'dynamicVideo', 'dynamicAfterEffects']
            },
            publish: {
                previewType: 'social'
            }
        },
        adSetup: {
            hasAdPlacements: true
        }
    },
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'adSetup',
            title: 'Ad setup',
            type: 'component',
            component: 'adSetup'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
