import React, { useEffect, useState } from 'react';
import { CreativeV2, CreativeV2TemplateEnriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import CreativeOverview from 'components/creatives-v2/components/creative-overview';
import useBrick from 'components/bricks/hooks/useBrick';

interface Props {
    blockModel: string;
}

const BricksMetaAdCreatives: React.FC<Props> = ({ blockModel }) => {
    const { brick } = useBrick();
    const [creative, setCreative] = useState<CreativeV2 | null>(null);
    useEffect(() => {
        if (blockModel) {
            const creatives: CreativeV2TemplateEnriched[] = brick?.data?.creatives;
            setCreative(creatives[0]);
        }
    }, [blockModel]);

    // Temporary, this tab will probably not exist in the future
    return (
        <div style={{ height: 800 }}>{creative && <CreativeOverview availableViews={['canvasFixed', 'canvasFree', 'single']} creatives={[creative]} />}</div>
    );
};

export default BricksMetaAdCreatives;
