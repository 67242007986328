import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import CreativeSelector from 'components/input/CreativeSelector';
import { withResources } from 'components/data/Resources';
import { FieldSetCopy } from 'components/input/Copy';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import Translation from 'components/data/Translation';
import CarouselTabs from 'components/bricks/components/shared/components/ad-setup/components/carousel-tabs';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import Divider from 'components/ui-components-v2/Divider';
import brickSetup from './setup';
import './styles/ad-setup.scss';

interface Props {
    handleSelectCreatives: (creatives) => void;
    facebook_cta: {
        [key: string]: string;
    };
}

const MultiFrame: React.FC<Props> = ({ handleSelectCreatives, facebook_cta }) => {
    const { adSetup, onChange, selectedFrame, creatives } = useAdSetupContext();

    const inputValidators = useMemo(() => {
        return {
            message: ValidateHelpers.getInputValidators('meta_ad', 'meta_ad-ad_setup-message'),
            name: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-name-${selectedFrame}`),
            description: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-description-${selectedFrame}`),
            link: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-link-${selectedFrame}`),
            caption: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-caption-${selectedFrame}`)
        };
    }, [selectedFrame]);

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <>
            <Accordion
                square
                title={Translation.get('adSetup.main', 'bricks')}
                classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details', summaryRoot: 'ad-setup__accordion__summary__root' }}>
                <div className="ad-setup__accordion__inputs">
                    <FieldSetCopy
                        label={Translation.get('adSetup.metaAd.message', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => onChange('message', newValue)}
                        value={adSetup.message}
                        validators={inputValidators.message}
                    />
                    <FieldSetSelectInput
                        label={Translation.get('adSetup.metaAd.callToAction.copy', 'bricks')}
                        useValueObject={false}
                        value={adSetup.cta}
                        onMutation={(newValue) => onChange('cta', newValue)}
                        options={facebook_cta}
                    />
                </div>
            </Accordion>

            <Accordion square={true} title="Multi frame" classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details--multi-frame' }}>
                <CarouselTabs identifier="meta_ad-ad_setup-carousel_tabs">
                    <div className="ad-setup__accordion__inputs">
                        <CreativeSelector
                            onMutation={(creatives) => handleSelectCreatives(creatives)}
                            multiple={multiple}
                            canEditCreative={canEditCreative}
                            value={creatives}
                            selectors={selectors}
                            templateTypes={templateTypes}
                        />

                        <FieldSetCopy
                            label={Translation.get('adSetup.metaAd.headline', 'bricks')}
                            useValueObject={false}
                            onMutation={(newValue) => onChange('name', newValue, true)}
                            value={adSetup.items?.[selectedFrame].name}
                            validators={inputValidators.name}
                        />

                        <FieldSetCopy
                            label={Translation.get('adSetup.description', 'bricks')}
                            onMutation={(newValue) => onChange('description', newValue, true)}
                            value={adSetup.items?.[selectedFrame].description}
                            useValueObject={false}
                            validators={inputValidators.description}
                        />

                        <Divider />

                        <FieldSetCopy
                            label={Translation.get('adSetup.metaAd.websiteUrl', 'bricks')}
                            useValueObject={false}
                            onMutation={(newValue) => onChange('link', newValue, true)}
                            value={adSetup.items?.[selectedFrame].link}
                            validators={inputValidators.link}
                        />
                        <FieldSetCopy
                            label={Translation.get('adSetup.metaAd.displayUrl', 'bricks')}
                            onMutation={(newValue) => onChange('caption', newValue, true)}
                            useValueObject={false}
                            value={adSetup.items?.[selectedFrame].caption}
                            validators={inputValidators.caption}
                        />
                    </div>
                </CarouselTabs>
            </Accordion>
        </>
    );
};

export default withResources(MultiFrame, ['facebook_cta']);
