import { MODEL_SETTINGS } from 'components/bricks/constants';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                identifier: 'meta_ad-title',
                label: 'Title',
                maxLength: 150,
                useValueObject: false,
                fixedModel: true
            },
            {
                type: 'dynamicData',
                title: 'Child title',
                identifier: 'meta_campaign-child_title',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_ad`,
                model: 'childTitle',
                itemType: 'input',
                label: 'Child title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Pointer',
                identifier: 'meta_campaign-pointer',
                condition: `additionalVars.brick.type==="subItem" && !additionalVars.multiSocial_ad`,
                model: 'pointer',
                itemType: 'input',
                label: 'Pointer',
                maxLength: 150,
                useValueObject: false
            },

            {
                type: 'dynamicData',
                title: 'Parent Pointer',
                identifier: 'meta_adset-parentPointer',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_ad`,
                model: 'parentPointer',
                itemType: 'input',
                label: 'Parent Pointer',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Publish',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'adAccountId',
                label: 'Ad account id',
                identifier: 'meta_ad-ad_account_id',
                metadataType: 'facebookAdAccount',
                config: {
                    fields: 'name'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'object_story_spec.page_id',
                identifier: 'meta_ad-page_id',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.adAccountId`,
                label: 'Page',
                metadataType: 'facebookPage',
                config: {
                    accountId: '{{{data.settings.adAccountId}}}'
                },
                useValueObject: false
            },
            // {
            //     type: 'metadataInput',
            //     model: 'object_story_spec.instagram_actor_id',
            //     identifier: 'meta_ad-instagram_actor_id',
            //     condition: `additionalVars.brick.${MODEL_SETTINGS}.adAccountId && additionalVars.brick.${MODEL_SETTINGS}.object_story_spec.page_id`,
            //     label: 'Instagram account',
            //     metadataType: 'instagramActorId',
            //     config: {
            //         pageId: '{{{[[blockModel]].data.settings.object_story_spec.page_id}}}',
            //         adAccountId: '{{{[[blockModel]].data.settings.adAccountId}}}',
            //         fields: 'username,id'
            //     },
            //     useValueObject: false
            // },
            {
                type: 'select',
                model: 'status',
                label: 'Status',
                identifier: 'meta_ad-status',
                tooltip: 'Status',
                setFirstOption: true,
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            }
            // {
            //     type: 'text',
            //     model: 'audience_id',
            //     identifier: 'meta_ad-audience_id',
            //     itemType: 'input',
            //     label: 'Audience id',
            //     useValueObject: false
            // },
            // {
            //     type: 'number',
            //     identifier: 'meta_ad-bid_amount',
            //     tooltip:
            //         'Bid amount for this ad which will be used in auction instead of the ad set bid_amount, if specified. Any updates to the ad set bid_amount will overwrite this value with the new ad set value.',
            //     model: 'bid_amount',
            //     label: 'Bid amount'
            // },
            // {
            //     type: 'text',
            //     model: 'conversion_domain',
            //     identifier: 'meta_ad-conversion_domain',
            //     itemType: 'input',
            //     label: 'Conversion domain',
            //     tooltip:
            //         'The domain where conversions happen. Required to create or update an ad in a campaign that shares data with a pixel. This field will be auto-populated for existing ads by inferring from destination URLs . Note that this field should contain only the first and second level domains, and not the full URL. For example facebook.com.',
            //     useValueObject: false
            // },
            // {
            //     type: 'text',
            //     model: 'date_format',
            //     itemType: 'input',
            //     identifier: 'meta_ad-date_format',
            //     tooltip: 'The format of the date',
            //     label: 'Date format',
            //     useValueObject: false
            // },
            // {
            //     type: 'number',
            //     tooltip: 'The sequence of the ad within the same campaign',
            //     model: 'display_sequence',
            //     identifier: 'meta_ad-display_sequence',
            //     label: 'Display sequence'
            // },
            // {
            //     type: 'number',
            //     tooltip: 'The ID of the draft ad.',
            //     model: 'draft_id',
            //     identifier: 'meta_ad-draft_id',
            //     label: 'Draft ad group id'
            // },
            // {
            //     type: 'buttonGroup',
            //     model: 'engagement_audience',
            //     identifier: 'meta_ad-engagement_audience',
            //     label: 'Engagement audience',
            //     tooltip: 'Flag to create a new audience based on users who engage with this ad',
            //     options: {
            //         false: 'OFF',
            //         true: 'ON'
            //     }
            // },
            // {
            //     type: 'buttonGroup',
            //     model: 'include_demolink_hashes',
            //     identifier: 'meta_ad-include_demolink_hashes',
            //     label: 'Include demolink hashes',
            //     tooltip: 'Include the demolink hashes.',
            //     options: {
            //         false: 'OFF',
            //         true: 'ON'
            //     }
            // },
            // {
            //     type: 'number',
            //     tooltip: 'The ID of the draft ad.',
            //     identifier: 'meta_ad-priority',
            //     model: 'priority',
            //     label: 'Priority'
            // },
            // {
            //     type: 'text',
            //     model: 'tracking_specs',
            //     identifier: 'meta_ad-tracking_specs',
            //     itemType: 'input',
            //     label: 'Tracking specs',
            //     useValueObject: false
            // }
        ]
    }
];

export default settings;
