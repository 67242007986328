import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const metadata: IEditorBlock[] = [
    {
        type: 'subSection',
        opened: true,
        title: 'Frame type',
        items: [
            {
                type: 'radioList',
                model: 'data.frameType',
                label: 'Frame type',
                options: [
                    {
                        key: 'singleFrame',
                        value: 'Single frame'
                    },
                    {
                        key: 'multipleFrames',
                        value: 'Multiple frames'
                    }
                ]
            },
            {
                type: 'radioList',
                model: 'data.adSetupType',
                label: 'Upload type',
                options: [
                    { key: 'custom', value: 'Custom upload' },
                    { key: 'template', value: 'Template based ads' }
                ]
            },
            {
                type: 'creativeSelector',
                model: 'data.creatives',
                label: 'Creatives',
                multiple: true
            }
        ]
    }
];

export default metadata;
