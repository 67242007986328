import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import adSetup from 'components/bricks/components/shared/components/ad-setup';
import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import metadata from './metadata';
import defaultData from './default-data';
import adSetupSingleFrame from './ad-setup-single-frame';
import adSetupMultiFrame from './ad-setup-multi-frame';
import creatives from './creatives';
import placements from '../data/placements';
import validators from './validators';
import { channels } from '../data/channels';

export default {
    settings,
    setup,
    output,
    metadata,
    defaultData,
    adSetup,
    creatives,
    briefing,
    adSetupSingleFrame,
    adSetupMultiFrame,
    placements,
    channels,
    validators
};
